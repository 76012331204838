import "../css/gutenberg.css"
import "moment/locale/de"
import "twin.macro"
import { Link, graphql } from "gatsby"
import AnchorNavigation from "../components/anchor-navigation"
import ArrowIcon from "../images/svgs/arrow-right.svg"
import Blocks from "../components/blocks"
import Container from "../components/container"
import extendBlocksWithSlugs from "../helpers/extend-blocks-with-slugs"
import Icon from "../components/icon"
import Indent from "../components/indent"
import React, { lazy, Suspense, useState } from "react"
import ReactHtmlParser from 'react-html-parser'
import SearchIcon from "../images/svgs/lupe.svg"
import Spacing from "../components/spacing"
import tw from "twin.macro"

const FachmesseTemplate = ({
  data: { page, organisationen },
}) => {

  const blocks = page.blocks?.blocks || []
  const [filteredBlocks, anchors, hasAnchors] = extendBlocksWithSlugs(blocks)
  organisationen = organisationen.edges.map(({ node }) => node)

  const [searchValue, setSearchValue] = useState("");
  const [filteredOrganisationen, setFilteredOrganisationen] = useState(organisationen);

  const handleSearch = e => {
    setSearchValue(e.target.value);
    if (searchValue) {
      const filteredData = organisationen.filter(item => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setFilteredOrganisationen(filteredData);
    } else {
      setFilteredOrganisationen(organisationen);
    }
  };

  return (
    <>
      <section tw="" id="aktive">

        <Container>
          <h1 tw="mb-4 max-w-3xl text-3xl font-bold lg:mb-9 lg:text-5xl">
            {ReactHtmlParser(page.title)}
          </h1>
          <div tw="grid grid-cols-12 gap-y-10 lg:gap-5">
            {hasAnchors && (
              <div tw="relative col-span-12 lg:col-span-3 xl:col-span-2">
                <AnchorNavigation anchors={anchors} />
              </div>
            )}
            <div tw="col-span-12 col-start-1 lg:col-span-9 lg:col-start-4 xl:col-start-3">
              <Blocks blocks={filteredBlocks} />
            </div>
          </div>
        </Container>


        <Spacing space="larges">
          <Container>

            <Indent>
              <div tw="mb-10 grid grid-cols-1 gap-4 md:grid-cols-1 lg:grid-cols-1">
                <div tw="relative mb-4">
                  <input
                    tw="h-16 w-full bg-[#eaeaea] text-xl border border-transparent px-2 pt-2.5 pb-4 outline-none placeholder:text-sm placeholder:text-anthracite-500 invalid:border-magenta-500 focus:border-anthracite-500 focus:ring-transparent disabled:bg-sand-200 md:px-3"
                    type="input"
                    placeholder="Suchbegriff eingeben"
                    onChange={handleSearch} value={searchValue}
                  />
                  <span tw="w-16 h-16 absolute right-0 top-0">
                    <Icon title="ArrowOrg" svg={SearchIcon} tw="pt-5 pl-5 h-10 w-10 flex-shrink-0" />
                  </span>
                </div>
                <div tw="relative overflow-hidden ">
                  {filteredOrganisationen.map((element, index) => (
                    <Link to={element.uri} tw="" id="Fachmesse">
                      <div tw="flex items-center relative w-full bg-sand-500 h-auto p-2 mb-4 m-auto">
                        <span tw="w-[calc(100%-70px)] font-bold lg:text-2xl p-3 sm:text-[1.14rem] sm:leading-[1.6rem]">{ReactHtmlParser(element.title)}</span>
                        <span tw="w-16 h-full bg-lilac-500 absolute right-0 top-0">
                            <Icon title="ArrowOrg" svg={ArrowIcon} tw="absolute top-1/2 -translate-y-1/2 pl-4 h-12 w-12 flex-shrink-0" />
                        </span>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </Indent>
          </Container>
        </Spacing>
      </section>
    </>
  )
}

/**export { Head } from "../components/head"**/

export default FachmesseTemplate

export const pageJQuery = graphql`
  query PageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      nodeType
      id
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      ...BlocksFragment
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    organisationen: allWpOrganisation(
      sort: {fields: link, order: ASC}
    ) {
      edges {
        node {
          id
          title
          status
          uri
          link
          organisation {
            instagram
            x
            homepage
            category
            linkedin
          }
        }
      }
    }
  }
`
